import { all, put, call, takeEvery } from 'redux-saga/effects'
import routeNames from 'routeNames/pageRouteNames'
import getMenuData from 'services/menu'
import DesksService from 'services/desks'
import actions from './actions'

const deskService = new DesksService()

export function* GET_DATA({ payload }) {
  const desks = yield call(deskService.getDesks)
  const menuData = yield call(getMenuData, payload)
  menuData.push({
    category: true,
    title: 'Desks',
    key: 'q7r3h3',
    scope: routeNames.DESKS.scope,
  })
  desks.data.data.forEach((element) => {
    if (element.status === 1) {
      const participantUsers = element.participantUsers.map((pU) => pU.id)
      const assignedUsers = element.assignedUsers.map((pU) => pU.id)

      if (
        (payload.isDefault && payload.isDefault.includes(1)) ||
        participantUsers.includes(payload.id) ||
        assignedUsers.includes(payload.id)
      )
        menuData.push({
          title: element.name,
          key: element.id,
          url: routeNames.DESK_TICKETS.path.replace(':id', element.id),
          scope: routeNames.DESK_TICKETS.scope,
          icon: element.icon,
          isImage: true,
        })
    }
  })

  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuData,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_DATA, GET_DATA),
    // run once on app load to fetch menu data
  ])
}
