import apiClient from 'services/axios'
import routeNames from 'routeNames/backendRouteNames'

export default class TicketService {
  getDeskTickets = async ({ id, params }) => {
    let route = routeNames.GET_DESK_TICKETS.path
    route = route.replace(':id', id)
    return apiClient.get(route, { params })
  }

  getTickets = async ({ params }) => {
    return apiClient.get(routeNames.GET_TICKETS.path, { params })
  }

  getTicketById = async ({ id, params }) => {
    let route = routeNames.GET_TICKET.path
    route = route.replace(':id', id)
    return apiClient.get(route, { params })
  }

  createTicket = async ({ body }) => {
    return apiClient.post(routeNames.CREATE_TICKET.path, body)
  }

  getComments = async ({ ticketId }) => {
    let route = routeNames.GET_COMMENT.path
    route = route.replace(':ticketId', ticketId)
    return apiClient.get(route)
  }

  creatComments = async ({ ticketId, body }) => {
    let route = routeNames.CREATE_COMMENT.path
    route = route.replace(':ticketId', ticketId)
    return apiClient.post(route, body)
  }

  updateStatus = async ({ ticketId, body }) => {
    let route = routeNames.UPDATE_TICKET.path
    route = route.replace(':ticketId', ticketId)
    return apiClient.patch(route, body)
  }

  approveTicket = async ({ ticketId, approve }) => {
    let route = routeNames.APPROVE_TICKET.path
    route = route.replace(':ticketId', ticketId)
    return apiClient.patch(route, { approve })
  }

  deleteTicket = async ({ ticketId }) => {
    let route = routeNames.DELETE_TICKET.path
    route = route.replace(':ticketId', ticketId)

    return apiClient.patch(route)
  }

  deleteComment = async ({ id }) => {
    let route = routeNames.DELETE_COMMENT.path
    route = route.replace(':id', id)

    return apiClient.patch(route)
  }
}
