const routeNames = {
  LOGIN: { path: '/auth/login', scope: null },
  CREATE_DESK: { path: '/desks/:id', scope: ['desk:create'] },
  DESKS: { path: '/desks', scope: ['desk:read', 'desk:create', 'desk:update', 'desks:read'] },
  DESK: {
    path: '/desks/:id',
    scope: [
      'desk:read',
      'ticket:read',
      'ticket:delete',
      'ticket:update',
      'comments:read',
      'comments:create',
      'comments:delete',
    ],
  },
  DESK_TICKETS: {
    path: '/desks/:id/tickets',
    scope: ['desk:read', 'ticket:read', 'ticket:update'],
  },
  TICKETS: { path: '/tickets', scope: ['tickets:read'] },
  TICKET: {
    path: '/ticket/:deskId/:id',
    scope: [
      'ticket:read',
      'ticket:update',
      'ticket:delete',
      'comments:read',
      'comments:create',
      'comments:delete',
    ],
  },
  CREATE_TICKET: { path: '/ticket/create', scope: ['tickets:create'] },
  MY_PROFILE: { path: '/myProfile', scope: ['profile:read', 'profile:update'] },
  USERS: { path: '/users', scope: ['organization:read', 'users:read'] },
  USER: { path: '/user/:id', scope: ['user:read', 'user:update', 'roles:read'] },

  404: { path: '/auth/404', scope: null },
  500: { path: '/auth/500', scope: null },
}

export default routeNames
