import React from 'react'
import routeNames from 'routeNames/pageRouteNames'
import { history } from 'index'

const Authorizer = ({ Component, reqScope, userScope }) => {
  if (reqScope === null) {
    return <Component />
  }
  const scopes = []
  if (userScope)
    userScope.forEach((element) => {
      element.forEach((elem) => (scopes.includes(elem) ? {} : scopes.push(elem)))
    })

  const res = reqScope.map((r) => scopes.includes(r))
  // console.log(res)
  // console.log(scopes, reqScope)
  if (res.includes(false)) {
    history.push(routeNames[404].path)
    return null
  }

  return <Component />
}

export default Authorizer
