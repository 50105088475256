import routeNames from 'routeNames/pageRouteNames'

const MenuData = [
  {
    category: true,
    title: 'Dashboard',
    key: 'q7r345',
    scope: routeNames.DESK.scope,
  },
  {
    title: 'Dashboard',
    key: '__home',
    url: '/dashboard',
    scope: routeNames.DESK.scope,
    icon: 'fa fa-home',
  },
  {
    category: true,
    title: 'Users',
    key: 'q7r3h3',
    scope: routeNames.USERS.scope,
  },
  {
    title: 'Users',
    key: '__users',
    url: routeNames.USERS.path,
    scope: routeNames.USERS.scope,
    icon: 'fe fe-user',
  },
  {
    category: true,
    title: 'Manage Desks',
    key: 'q7r345',
    scope: routeNames.DESKS.scope,
  },
  {
    title: 'Desks',
    key: '__createDesk',
    url: routeNames.DESKS.path,
    scope: routeNames.DESKS.scope,
    icon: 'fa fa-tasks',
  },
]

export default async function getMenuData(payload = {}) {
  const { permissions } = payload
  const scopes = []
  if (permissions)
    permissions.forEach((element) => {
      element.forEach((elem) => (scopes.includes(elem) ? {} : scopes.push(elem)))
    })

  const finalbool = MenuData.map((md) => md.scope.every((val) => scopes.includes(val)))
  const finalMenu = []
  finalbool.forEach((elem, i) => (elem ? finalMenu.push(MenuData[i]) : {}))
  return finalMenu
}
