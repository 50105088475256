const actions = {
  SET_STATE: 'users/SET_STATE',
  GetUsers: 'users/GetUsers',
  GetUser: 'users/GetUser',
  GetOrganizations: 'users/GetOrganizations',
  UpdateUser: 'users/Update_User',
  GetParticipants: 'users/GetParticipants',
}

export default actions
