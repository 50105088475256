import apiClient from 'services/axios'
import routeNames from 'routeNames/backendRouteNames'

export default class UsersService {
  getOrganizations = async () => {
    return apiClient.get(routeNames.Get_ORGANIZATIONS.path)
  }

  getUsers = async ({ params }) => {
    return apiClient.get(routeNames.GET_USERS.path, {
      params,
    })
  }

  getUser = async ({ id }) => {
    let route = routeNames.GET_USER.path
    route = route.replace(':id', id)
    return apiClient.get(route)
  }

  updateUser = async ({ body, id }) => {
    let route = routeNames.UPDATE_USER.path
    route = route.replace(':userId', id)
    return apiClient.patch(route, body)
  }

  getDevelopers = async ({ params }) => {
    return apiClient.get(routeNames.GET_DEVELOPERS.path, { params })
  }

  getDevops = async ({ params }) => {
    return apiClient.get(routeNames.GET_DEVOPS.path, { params })
  }
}
