const actions = {
  SET_STATE: 'desks/SET_STATE',
  LOAD_DESK: 'desks/LOAD_DESK',
  LOAD_DESKS: 'desks/LOAD_DESKS',
  LOAD_TICKET_TYPE: 'desks/ LOAD_TICKET_TYPE',
  CREATE_DESK: 'desks/CREATE_DESK',
  UPDATE_DESK: 'desks/UPDATE_DESK',
}

export default actions
