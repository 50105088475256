import { all, takeEvery, put, call } from 'redux-saga/effects'
import DesksService from 'services/desks'
import { notification } from 'antd'
import { history } from 'index'
import actions from './actions'
import userActions from '../user/actions'

const services = new DesksService()

export function* CREATE_DESK({ payload }) {
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(services.createDesk, payload)
  if (response) {
    yield put({
      type: userActions.LOAD_CURRENT_ACCOUNT,
    })
    history.push('/desks')
    notification.success({
      message: 'Desk Created',
      description: 'You have successfully Created a Desk!',
    })
  }
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_DESK({ payload }) {
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(services.updateDesk, payload)
  if (response) {
    yield put({
      type: userActions.LOAD_CURRENT_ACCOUNT,
    })
    yield put({
      type: actions.LOAD_DESKS,
    })
    notification.success({
      message: 'Desk Updated',
      description: 'You have successfully Updated the Desk!',
    })
    history.push('/desks')
  }
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* LOAD_TICKET_TYPE({ payload }) {
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  yield put({
    type: actions.SET_STATE,
    payload: {
      ticketType: [],
    },
  })

  const success = yield call(services.getDeskTicketType, payload)
  const { data } = success.data
  if (success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        ticketType: data,
      },
    })
  }

  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* LOAD_DESK({ payload }) {
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  yield put({
    type: actions.SET_STATE,
    payload: {
      id: '',
      name: '',
      description: '',
      status: '',
      icon: '',
      tat: 0,
      structure: {},
      participantUsers: [],
      assignedUsers: [],
      assignedRoles: [],
      participantRoles: [],
      hasTickets: true,
    },
  })

  const success = yield call(services.getDesk, payload)
  if (success && success.data && success.data.data) {
    const {
      id,
      name,
      icon,
      status,
      description,
      assignedUsers,
      participantUsers,
      assignedRoles,
      participantRoles,
      tat,
      structure,
      hasTickets,
    } = success.data.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        id,
        name,
        description,
        status,
        icon,
        assignedUsers,
        participantUsers,
        assignedRoles,
        participantRoles,
        tat,
        structure,
        hasTickets,
      },
    })
  }

  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* LOAD_DESKS({ payload }) {
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const success = yield call(services.getDesks, payload)
  if (success && success.data && success.data.data) {
    let totalTickets = 0
    success.data.data.forEach((element) => {
      totalTickets += element.deskTickets
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        desks: success.data.data,
        totalTickets,
      },
    })
  }

  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE_DESK, CREATE_DESK),
    takeEvery(actions.UPDATE_DESK, UPDATE_DESK),
    takeEvery(actions.LOAD_TICKET_TYPE, LOAD_TICKET_TYPE),
    takeEvery(actions.LOAD_DESK, LOAD_DESK),
    takeEvery(actions.LOAD_DESKS, LOAD_DESKS),
  ])
}
