import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import TicketService from 'services/tickets'
import { history } from 'index'
import routeNames from 'routeNames/pageRouteNames'
import actions from './actions'
import userActions from '../user/actions'

const services = new TicketService()

export function* LOAD_TICKETS({ payload = { params: {} } }) {
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(services.getDeskTickets, payload)
  if (response) {
    const tickets = (response.data && response.data.data) || []
    const pagination = response.data && response.data.meta && response.data.meta.pagination
    yield put({
      type: actions.SET_STATE,
      payload: {
        tickets,
        pagination,
      },
    })
  }
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_TICKET({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(services.createTicket, payload)

  if (response) {
    notification.success({
      message: 'Ticket Created',
      description: 'You have successfully Created a Ticket!',
    })

    history.goBack()
    yield put({
      type: actions.LOAD_TICKETS,
      payload: { id: payload.body.deskId },
    })
  }
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_COMMENT({ payload }) {
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  yield call(services.creatComments, payload)
  const response = yield call(services.getComments, { ticketId: payload.ticketId })
  const comments = response.data.data
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        comments,
      },
    })
  }
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* LOAD_TICKET({ payload }) {
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  yield put({
    type: actions.SET_STATE,
    payload: {
      id: '',
      assignedTo: null,
      status: null,
    },
  })

  const response = yield call(services.getTicketById, payload)

  if (response) {
    const {
      id,
      // uuid,
      referenceId,
      type,
      status,
      user,
      formFields,
      participants,
      updates,
      assignedTo,
    } = response && response.data && response.data.data
    if (!id) history.push('/desks')
    else {
      const response2 = yield call(services.getComments, { ticketId: id })
      const comments = response2 && response2.data && response2.data.data
      yield put({
        type: actions.SET_STATE,
        payload: {
          id,
          referenceId,
          type,
          status,
          user,
          formFields,
          participants,
          updates,
          comments,
          assignedTo,
        },
      })
    }
  } else {
    history.push('/pages/auth/404')
  }
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* STATUS_UPDATE({ payload }) {
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(services.updateStatus, payload)
  if (response) {
    notification.success({
      message: response.data.data,
    })
  }
  yield put({
    type: actions.LOAD_TICKET,
    payload: { id: payload.ticketId, params: { deskId: payload.deskId } },
  })
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* APPROVE_TICKET({ payload }) {
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(services.approveTicket, payload)
  if (response && response.data && response.data.data) {
    notification.success({
      message: response.data.data,
    })
    yield put({
      type: actions.LOAD_TICKET,
      payload: { id: payload.ticketId, params: { deskId: payload.deskId } },
    })
  }
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* DeleteTicket({ payload }) {
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(services.deleteTicket, payload)
  if (response) {
    notification.success({
      message: 'Ticket Deleted Successfully',
    })
    yield put({
      type: actions.LOAD_TICKETS,
      payload: { id: payload.deskId },
    })
    history.push(routeNames.DESK_TICKETS.path.replace(':id', payload.deskId))
  }
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* DeleteComment({ payload }) {
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(services.deleteComment, payload)
  if (response) {
    notification.success({
      message: 'Comment Deleted Successfully',
    })

    const response2 = yield call(services.getComments, { ticketId: payload.ticketId })
    const comments = response2 && response2.data && response2.data.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        comments,
      },
    })
  }
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_TICKETS, LOAD_TICKETS),
    takeEvery(actions.LOAD_TICKET, LOAD_TICKET),
    takeEvery(actions.CREATE_TICKET, CREATE_TICKET),
    takeEvery(actions.CREATE_COMMENT, CREATE_COMMENT),
    takeEvery(actions.STATUS_UPDATE, STATUS_UPDATE),
    takeEvery(actions.APPROVE_TICKET, APPROVE_TICKET),
    takeEvery(actions.DELETE_TICKET, DeleteTicket),
    takeEvery(actions.DELETE_Comment, DeleteComment),
  ])
}
