import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import users from './users/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import ticketsReducer from './tickets/reducers'
import desksReducer from './desks/reducers'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    users,
    menu,
    settings,
    ticketsReducer,
    desksReducer,
  })
