import actions from './actions'

const initialState = {
  tickets: [],
  loading: false,
  id: '',
  referenceId: '',
  type: '',
  status: 0,
  userId: '',
  deskId: '',
  user: {},
  formFields: {},
  participants: [],
  updates: [],
  comments: [],
  assignedTo: null,
  pagination: {},
}

export default function ticketsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
