import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'
import routeNames from 'routeNames/pageRouteNames'

import Layout from 'layouts'
import Authorizer from 'authorizer'
import Breadcrumbs from '@vb/components/Breadcrumbs'

const routes = [
  {
    path: '/dashboard',
    scope: routeNames.DESK.scope,
    Component: lazy(() => import('pages/dashboard')),
    exact: true,
  },
  {
    path: routeNames.DESKS.path,
    scope: routeNames.DESKS.scope,
    Component: lazy(() => import('pages/desks/allDesk')),
    exact: true,
  },
  {
    path: routeNames.DESK_TICKETS.path,
    scope: routeNames.DESK_TICKETS.scope,
    Component: lazy(() => import('pages/tickets/allTickets')),
    exact: true,
  },
  {
    path: routeNames.CREATE_TICKET.path,
    scope: routeNames.CREATE_TICKET.scope,
    Component: lazy(() => import('pages/tickets/createTicket')),
    exact: true,
  },
  {
    path: routeNames.CREATE_DESK.path,
    scope: routeNames.CREATE_DESK.scope,
    Component: lazy(() => import('pages/desks/createDesk')),
    exact: true,
  },
  {
    path: routeNames.TICKET.path,
    scope: routeNames.TICKET.scope,
    Component: lazy(() => import('pages/tickets/ticket')),
    exact: true,
  },
  {
    path: routeNames.USERS.path,
    scope: routeNames.USERS.scope,
    Component: lazy(() => import('pages/users/allUsers')),
    exact: true,
  },
  {
    path: routeNames.MY_PROFILE.path,
    scope: routeNames.MY_PROFILE.scope,
    Component: lazy(() => import('pages/users/myProfile')),
    exact: true,
  },
  {
    path: routeNames.USER.path,
    scope: routeNames.USER.scope,
    Component: lazy(() => import('pages/users/user')),
    exact: true,
  },
  {
    path: routeNames.LOGIN.path,
    scope: routeNames.LOGIN.scope,
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: routeNames[404].path,
    scope: routeNames[404].scope,
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: routeNames[500].path,
    scope: routeNames[500].scope,
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
]

const mapStateToProps = ({ settings, dispatch, user }) => ({
  routerAnimation: settings.routerAnimation,
  dispatch,
  userScope: user.permissions,
})

const Router = ({ history, routerAnimation, userScope }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={(state) => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    {/* VB:REPLACE-NEXT-LINE:ROUTER-REDIRECT */}
                    <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                    {routes.map(({ path, Component, exact, scope }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Breadcrumbs />
                                <Authorizer
                                  Component={Component}
                                  reqScope={scope}
                                  userScope={userScope}
                                />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
