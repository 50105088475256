import { all, takeEvery, put, call } from 'redux-saga/effects'
import UsersService from 'services/users'
import { notification } from 'antd'
import { history } from 'index'
import actions from './actions'
import userActions from '../user/actions'

const services = new UsersService()

export function* GetOrganizations() {
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const success = yield call(services.getOrganizations)
  if (success) {
    const { data } = success.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        organizations: data,
      },
    })
  }

  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}
export function* GetUsers({ payload }) {
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const success = yield call(services.getUsers, payload)
  if (success) {
    const { data, meta } = success.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        users: data,
        pagination: meta.pagination,
      },
    })
  }

  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}
export function* GetParticipants({ payload }) {
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const success = yield call(services.getUsers, payload)
  if (success) {
    const { data } = success.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        participants: data,
      },
    })
  }

  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GetUser({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      selectedUser: {},
    },
  })
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const success = yield call(services.getUser, payload)
  const { data } = success.data
  if (success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        selectedUser: data,
      },
    })
  }

  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}
export function* UpdateUser({ payload }) {
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const res = yield call(services.updateUser, payload)
  if (res) {
    yield put({
      type: userActions.LOAD_CURRENT_ACCOUNT,
    })
    notification.success({
      message: "User's Profile Updated Successfully",
    })
    history.push('/users')
  }
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GetUsers, GetUsers),
    takeEvery(actions.GetUser, GetUser),
    takeEvery(actions.GetOrganizations, GetOrganizations),
    takeEvery(actions.UpdateUser, UpdateUser),
    takeEvery(actions.GetParticipants, GetParticipants),
  ])
}
