/* eslint-disable */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import AuthService from 'services/auth'
import { v4 as uuid } from 'uuid'
import routeNames from 'routeNames/pageRouteNames'
import actions from './actions'
import menuActions from '../menu/actions'
import firebase from '../../firebase'
import ticketActions from '../tickets/actions'
import usersActions from '../users/actions'

const services = new AuthService()

export function* LOGIN({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const success = yield call(services.login, payload)

  if (success) {
    const accessToken = success.data.data.access_token
    localStorage.setItem('accessToken', accessToken)
    yield put({
      type: actions.LOAD_CURRENT_ACCOUNT,
    })
    yield history.push('/dashboard')
    notification.success({
      message: 'Logged In',
      description: 'You have successfully logged in!',
    })

    // const machineId = uuid()
    // localStorage.setItem('machineId', machineId)

    // const messaging = firebase.messaging()
    // yield messaging.requestPermission()
    // const fcmToken = yield messaging.getToken()
    // if (fcmToken) {
    //   yield call(services.addDevice, { body: { fcmToken, machineId } })
    // }
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  if (localStorage.getItem('accessToken')) {
    const response = yield call(services.getProfile)
    if (response) {
      let allRoles = yield call(services.getRoles)
      allRoles = allRoles.data.data
      const {
        id,
        name,
        profilePicture,
        status,
        email,
        roles,
        organization,
        permissions,
      } = response.data.data
      yield put({
        type: actions.SET_STATE,
        payload: {
          authorized: true,
          id,
          name,
          profilePicture,
          status,
          email,
          allRoles,
          roles,
          organization,
          permissions,
        },
      })
      const isDefault = roles.map((r) => r.isDefault)
      yield put({
        type: menuActions.GET_DATA,
        payload: { permissions, id, isDefault },
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  localStorage.clear('accessToken')
  localStorage.clear('machineId')

  yield put({
    type: usersActions.SET_STATE,
    payload: {
      users: [],
      selectedUser: null,
      organizations: [],
      authorized: false,
      loading: false,
      developers: [],
      devops: [],
      pagination: {},
    },
  })
  yield put({
    type: ticketActions.SET_STATE,
    payload: {
      tickets: [],
      loading: false,
      id: '',
      uuid: '',
      referenceId: '',
      type: '',
      status: '',
      userId: '',
      user: {},
      formFields: {},
      participants: [],
      updates: [],
      comments: [],
      assignedTo: null,
      pagination: {},
    },
  })
  yield history.push(routeNames.LOGIN.path)
  notification.success({
    message: 'Logged Out',
    description: 'You have successfully logged out!',
  })
  yield put({
    type: actions.SET_STATE,
    payload: {
      id: '',
      name: '',
      allRoles: [],
      profilePicture: '',
      organizationId: '',
      status: '',
      email: '',
      roles: '',
      organization: '',
      permissions: [],
      authorized: false,
      loading: false,
    },
  })
}

export function* UPDATE_PROFILE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(services.updateProfile, { body: payload })
  if (response) {
    notification.success({
      message: 'Profile Updated Successfully',
    })
    yield put({
      type: actions.LOAD_CURRENT_ACCOUNT,
    })
    history.push('/dashboard')
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.UPDATE_PROFILE, UPDATE_PROFILE),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
