import firebase from 'firebase'

const config = {
  apiKey: 'AIzaSyAYFMGpTzEfN7OiaID1nNykAVEhiseS0vI',
  authDomain: 'service-desk-334413.firebaseapp.com',
  projectId: 'service-desk-334413',
  storageBucket: 'service-desk-334413.appspot.com',
  messagingSenderId: '123015709778',
  appId: '1:123015709778:web:3a62ffd6fc951b347b8b8b',
  measurementId: 'G-C856GXCYHH',
}

firebase.initializeApp(config)
export default firebase
