import apiClient from 'services/axios'
import routeNames from 'routeNames/backendRouteNames'

export default class AuthService {
  login = async ({ body }) => {
    return apiClient.post(routeNames.LOGIN.path, body)
  }

  getProfile = async () => {
    return apiClient.get(routeNames.GET_PROFILE.path)
  }

  updateProfile = async ({ body }) => {
    return apiClient.patch(routeNames.UPDATE_PROFILE.path, body)
  }

  getRoles = async () => {
    return apiClient.get(routeNames.GET_ROLES.path)
  }

  addDevice = async ({ body }) => {
    console.log(body)
    return apiClient.post(routeNames.ADD_DEVICE.path, body)
  }
}
