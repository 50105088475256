const actions = {
  SET_STATE: 'tickets/SET_STATE',
  LOAD_TICKETS: 'tickets/LOAD_TICKETS',
  LOAD_TICKET: 'tickets/LOAD_TICKET',
  CREATE_TICKET: 'tickets/Create',
  CREATE_COMMENT: 'tickets/CreateComment',
  STATUS_UPDATE: 'ticket/Status_Update',
  APPROVE_TICKET: 'ticket/APPROVE_TICKET',
  DELETE_TICKET: 'tickets/Delete',
  DELETE_Comment: 'tickets/Delete_Comment',
}

export default actions
