import actions from './actions'

const initialState = {
  users: [],
  selectedUser: null,
  organizations: [],
  loading: false,
  pagination: {},
}

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
