import actions from './actions'

const initialState = {
  id: '',
  name: '',
  description: '',
  status: '',
  icon: '',
  tat: 0,
  structure: {},
  organizationId: '',
  participantUsers: [],
  assignedUsers: [],
  ticketType: [],
  desks: [],
  assignedRoles: [],
  participantRoles: [],
  hasTickets: true,
  totalTickets: 0,
}

export default function deskReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
