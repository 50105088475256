const routeNames = {
  LOGIN: { path: '/auth/login' },
  ADD_DEVICE: { path: '/devices' },

  CREATE_TICKET: { path: '/tickets' },
  GET_TICKETS: { path: '/tickets' },
  GET_TICKET: { path: '/tickets/:id' },
  APPROVE_TICKET: { path: '/tickets/:ticketId/approve' },
  UPDATE_TICKET: { path: '/tickets/:ticketId/perform-action' },
  DELETE_TICKET: { path: 'tickets/:ticketId/delete' },

  GET_COMMENT: { path: '/tickets/:ticketId/comments' },
  CREATE_COMMENT: { path: '/tickets/:ticketId/comments' },
  DELETE_COMMENT: { path: '/tickets/:id/comments' },

  GET_PROFILE: { path: '/users/my-profile' },
  UPDATE_PROFILE: { path: '/users/my-profile' },
  GET_USERS: { path: '/users' },
  GET_USER: { path: '/users/:id' },
  UPDATE_USER: { path: '/users/:userId/perform-action' },

  GET_ROLES: { path: '/users/roles' },
  Get_ORGANIZATIONS: { path: '/organization' },

  CREATE_DESK: { path: '/desks' },
  GET_DESKS: { path: '/desks' },
  GET_DESK: { path: '/desks/:id' },
  GET_DESK_TICKETS: { path: '/desks/:id/tickets' },
  GET_DESK_TICKET_TYPE: { path: '/desks/:id/ticket-type' },
  UPDATE_DESK: { path: '/desks/:id' },

  GET_MEDIA_SIGNED_URL: { path: '/media/upload-urls' },
}

export default routeNames
