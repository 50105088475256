import React from 'react'
import { connect } from 'react-redux'
import style from './style.module.scss'
import LogoD from '../../../helper/Logo-real.svg'
import LogoW from '../../../helper/Logo-white.svg'

const mapStateToProps = ({ settings }) => ({ settings, menuColor: settings.menuColor })

const Footer = ({ menuColor }) => {
  return (
    <div className={style.footer}>
      <div className={style.footerInner}>
        <a
          href="https://squareboat.com/"
          target="_blank"
          rel="noopener noreferrer"
          className={style.logo}
        >
          <div className={style.name}>
            <img
              style={{ width: '185px' }}
              src={menuColor !== 'dark' ? LogoD : LogoW}
              alt="Squareboat"
            />
          </div>
        </a>
        <br />
        <p className="mb-0">
          Copyright © {new Date().getFullYear()}{' '}
          <a href="https://squareboat.com/" target="_blank" rel="noopener noreferrer">
            Squareboat.com
          </a>
        </p>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(Footer)
