import apiClient from 'services/axios'
import routeNames from 'routeNames/backendRouteNames'

export default class DesksService {
  createDesk = async ({ body }) => {
    // console.log('in the create desk')
    return apiClient.post(routeNames.CREATE_DESK.path, body)
  }

  getDesks = async () => {
    return apiClient.get(routeNames.GET_DESKS.path)
  }

  getDesk = async ({ id }) => {
    // console.log('🚀 ~ file: index.js ~ line 15 ~ DesksService ~ getDesk= ~ id', id)
    let route = routeNames.GET_DESK.path
    route = route.replace(':id', id)
    return apiClient.get(route)
  }

  updateDesk = async ({ body, id }) => {
    let route = routeNames.UPDATE_DESK.path
    route = route.replace(':id', id)
    return apiClient.patch(route, body)
  }

  getDeskTickets = async ({ id }) => {
    let route = routeNames.GET_DESK_TICKETS.path
    route = route.replace(':id', id)
    return apiClient.get(route)
  }

  getDeskTicketType = async ({ id }) => {
    let route = routeNames.GET_DESK_TICKET_TYPE.path
    route = route.replace(':id', id)
    return apiClient.get(route)
  }
}
